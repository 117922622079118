<template>
  <div>
    <Navbar/>

    <section>
      <h2>Πολιτική Απορρήτου</h2>
      <div class="privacy-container">
        <div class="bussiness-specs">
          <p class="p-align mt-1">
            Στην SLM θέλουμε οι αποστολείς και οι παραλήπτες των ταχυδρομικών αντικειμένων, να γνωρίζουν και να έχουν 
            έλεγχο στο ποια δεδομένα τους μοιράζονται μαζί μας και στο τι κάνουμε εμείς με αυτά. Το κείμενο αυτό (που 
            ονομάζεται Πολιτική Απορρήτου) εξηγεί με απλά λόγια αλλά και σαφήνεια τι δεδομένα συλλέγουμε για εσάς στο 
            πλαίσιο λειτουργίας των υπηρεσιών μας και πώς τα χρησιμοποιούμε, σύμφωνα με το περιεχόμενο του Κανονισμού 
            (ΕΕ) 2016/679, όπως επίσης των Ν.4624/2019 και Ν.2472/1997.
          </p>
        </div>
        <div class="bussiness-specs">
          <h5>Υπεύθυνος Επεξεργασίας</h5>
          <p class="p-align mt-1">
            Η εταιρία «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ ΜΟΝΟΠΡΟΣΩΠΗ ΙΔΙΩΤΙΚΗ ΚΕΦΑΛΑΙΟΥΧΙΚΗ ΕΤΑΙΡΙΑ» [εφεξής «SLM» ή «εμείς»] 
            που βρίσκεται στην Οδό Αγίας Άννης 7, Τ.Κ. 118 55 Αθήνα, είναι σύμφωνα με το νόμο υπεύθυνη για την 
            επεξεργασία των προσωπικών δεδομένων των αποστολέων και των παραληπτών, στο πλαίσιο της παροχής ταχυδρομικών 
            υπηρεσιών [εφεξής «Υπηρεσίες SLM»]. Συγκεκριμένα, οι υπηρεσίες αυτές περιλαμβάνουν τη συμβατική σχέση που 
            αναπτύσσουμε με τους αποστολείς και τους παραλήπτες στο πλαίσιο της διεκπεραίωσης της παραγγελίας από την 
            παραλαβή του ταχυδρομικού αντικειμένου έως την εναπόθεση αυτού.
          </p>
        </div>
        <div class="bussiness-specs">
          <h5>Προσωπικά Δεδομένα που Συλλέγουμε</h5>
          <p class="p-align mt-1">
            Στο πλαίσιο παροχής των Υπηρεσιών SLM, συλλέγουμε και επεξεργαζόμαστε περαιτέρω τα ακόλουθα προσωπικά σας δεδομένα:
            <ul>
              <li>Στοιχεία ταυτοποίησης, όπως ονοματεπώνυμο του αποστολέα/παραλήπτη</li>
              <li>Στοιχεία επικοινωνίας, όπως διευθύνσεις email, πλήρη ταχυδρομική διεύθυνση, αριθμό τηλεφώνου</li>
              <li>Πληροφορίες που αφορούν στο είδος της παρεχόμενης υπηρεσίας</li>
              <li>Στοιχεία που αφορούν στο ποσό της τυχόν καταβληθείσας αντικαταβολής αλλά και την ημερομηνία/ώρα πληρωμής</li>
              <li>Στοιχεία που σχετίζονται με το διακινούμενο ταχυδρομικό αντικείμενο, όπως ο αριθμός παραγγελίας, ο κωδικός 
                του ταχυδρομικού αντικειμένου, το είδος και το βάρος του ταχυδρομικού αντικειμένου, αλλά και τυχόν 
                κατηγοριοποίηση αυτού ως απολεσθέν ή ανεπίδοτο
              </li>
              <li>Στοιχεία που σχετίζονται με τη διακίνηση του ταχυδρομικού αντικειμένου, όπως ημερομηνία/ώρα/τοποθεσία της 
                παραλαβής, αναχώρησης/παραλαβής από το κέντρο διαλογής, της αναμενόμενης παράδοσης, της αποτυχημένης 
                παράδοσης και της αναπρογραμματισμένης παράδοσης, της προγραμματισμένης επιστροφής, της επιστροφής στον 
                αποστολέα και εν γένει πάσης φύσεως timestamps (σημειώσεις χρόνου) που ενδέχεται να προκύψουν από το 
                σύστημα παρακολούθησης της αποστολής, αλλά και κάθε άλλη πληροφορία σχετιζόμενη με τη μεταφορά του φακέλου/δέματος
              </li>
              <li>Στοιχεία που αφορούν στην επικοινωνία μαζί μας, όπως το περιεχόμενο της επικοινωνίας, το περιεχόμενο του SMS/Viber 
                μηνύματος που λαμβάνετε, ή/και οποιοδήποτε σχετικό αίτημα προς εμάς, όπως π.χ. αίτημα επιστροφής του ταχυδρομικού αντικειμένου
              </li>
              <li>Στοιχεία που αφορούν σε κάποιο αίτημα αποζημίωσης, όπως η αιτία και το ποσό της αποζημίωσης, στοιχεία τραπεζικού λογαριασμού, 
                όπως αριθμός λογαριασμού, ΙΒΑΝ, ονομασία δικαιούχου, τυχόν πόρισμα της Επιτροπής Επίλυσης Διαφορών, τυχόν φωτογραφίες του 
                κατεστραμμένου ταχυδρομικού αντικειμένου
              </li>
              <li>Πληροφορίες που συλλέγουμε μέσω τεχνολογικών λύσεων, όπως τα cookies, η διεύθυνση IP, τεχνικές πληροφορίες σύνδεσης της 
                συσκευής σας, στοιχεία ανάλυσης χρήσης της ιστοσελίδας μας
              </li>
            </ul>
          </p>
        </div>
        <div class="bussiness-specs">
          <h5>Γιατί τα συλλέγουμε</h5>
          <p class="p-align mt-1">
            Τα παραπάνω προσωπικά σας δεδομένα γίνονται αντικείμενο επεξεργασίας από εμάς αποκλειστικά για την εξυπηρέτηση των παρακάτω 
            σκοπών και σύμφωνα με τις ακόλουθες νόμιμες βάσεις:
          </p>
        </div>
        <div class="bussiness-specs">
          <h5><u>Σύμβαση</u></h5>
          <p class="p-align mt-1 ml-1">
            Η SLM επεξεργάζεται τα δεδομένα σας στο πλαίσιο της εκτέλεσης της σύμβασης αναφορικά με τη διεκπεραίωση της αποστολής του 
            ταχυδρομικού αντικειμένου που συνάπτει μαζί σας.
          </p>
          <p class="p-align mt-1 ml-1">
            Συγκεκριμένα, επεξεργαζόμαστε τα στοιχεία σας για να διεκπεραιώσουμε προσηκόντως την αποστολή, και ειδικότερα στο πλαίσιο 
            της παραλαβής και της εναπόθεσης του ταχυδρομικού αντικειμένου, αλλά και στο πλαίσιο της διαχείρισης των 
            απολεσθέντων/ανεπίδοτων και των επιστροφών στον αποστολέα. Επεξεργαζόμαστε, επίσης, τα στοιχεία σας στο πλαίσιο της 
            επικοινωνίας που μπορεί να έχετε μαζί μας, αλλά και κατά την ενημέρωσή σας αναφορικά με το στάδιο στο οποίο βρίσκεται 
            το ταχυδρομικό αντικείμενο ή σχετικά με τυχόν αποτυχία παράδοσης. Τέλος, τα στοιχεία σας γίνονται αντικείμενο επεξεργασίας 
            στο πλαίσιο της συλλογής, αποτύπωσης και κατάθεσης των αντικαταβολών.
          </p>
        </div>
        <div class="bussiness-specs">
          <h5><u>Έννομο Συμφέρον</u></h5>
          <p class="p-align mt-1 ml-1">
            Αποτελεί έννομο συμφέρον της SLM να επεξεργάζεται τα στοιχεία σας στο πλαίσιο της αποτύπωσης του όγκου των παραγγελιών 
            που διεκπεραιώθηκαν για σκοπούς τιμολόγησης και οικονομικής εκκαθάρισης με τους πελάτες της, αλλά και κατά την εκπόνηση 
            των καθημερινών ελέγχων της αποθήκης. Επίσης, είναι στο έννομο συμφέρον της SLM η αποθήκευση των στοιχείων σας για 
            ιστορικούς/backup λόγους αλλά και για τη διαχείριση, την αποτροπή και την αντίδραση σε περιστατικά ασφαλείας. Τέλος, 
            αποτελεί έννομο συμφέρον της SLM να επεξεργάζεται τα στοιχεία σας στο πλαίσιο της διαχείρισης νομικών αξιώσεων, στις 
            οποίες εμπλέκεται η εταιρεία, αλλά και στο πλαίσιο αναζήτησης νομικών συμβουλών.
          </p>
        </div>
        <div class="bussiness-specs">
          <h5><u>Νομική Υποχρέωση</u></h5>
          <p class="p-align mt-1 ml-1">
            Η SLM είναι υποχρεωμένη από τη νομοθεσία να συνεργάζεται με τις αρμόδιες αρχές, ιδίως κατόπιν αιτημάτων τους, για την 
            καταπολέμηση συγκεκριμένων περιστατικών που αφορούν σε άρση του απορρήτου των επικοινωνιών ή λοιπές παραβάσεις της 
            νομοθεσίας. Είμαστε επίσης υποχρεωμένοι από τη νομοθεσία να διαχειριζόμαστε και να απαντούμε στα αιτήματά σας που 
            αφορούν στην προστασία των προσωπικών σας δεδομένων και στα αιτήματα σας για αποζημίωση σύμφωνα με το κανονιστικό 
            πλαίσιο, όπως εκάστοτε ισχύει.
          </p>
        </div>
        <div class="bussiness-specs">
          <h5><u>Συγκατάθεση</u></h5>
          <p class="p-align mt-1 ml-1">
            Εφόσον δώσετε τη συγκατάθεσή σας για τη χρήση τεχνολογιών όπως τα cookies στη συσκευή σας, συλλέγεται αυτόματα 
            πληροφορία για εσάς, σύμφωνα με την Πολιτική Cookies της SLM. Μπορείτε ανά πάσα στιγμή να αλλάξετε την επιλογή 
            σας για τα cookies και να αποσύρετε τη συγκατάθεσή σας, στο κάτω μέρος της Πολιτικής Cookies της SLM.
          </p>
        </div>
        <div class="bussiness-specs">
          <h5>Με Ποιους τα Μοιραζόμαστε</h5>
          <p class="p-align mt-1">
            Τα προσωπικά δεδομένα σας, συλλέγονται και γίνονται αντικείμενο περαιτέρω επεξεργασίας από την εξουσιοδοτημένη 
            για αυτό ομάδα της SLM, με πλήρη σεβασμό στην ιδιωτικότητά σας. Στο πλαίσιο της παροχής των Υπηρεσιών SLM, 
            αποδέκτες των προσωπικών σας δεδομένων μπορεί να είναι οι ακόλουθες κατηγορίες συνεργατών μας:
            <ul>
              <li>Πάροχοι τεχνολογικών λύσεων, όπως η Xero, η Northstar και η Pickpack. Τα δεδομένα σας ενδέχεται να διαβιβαστούν 
                εκτός Ευρωπαϊκού Χώρου και ιδίως στις ΗΠΑ από τις εταιρίες αυτές. Στο πλαίσιο αυτό, εφαρμόζουμε επιπλέον 
                προστατευτικά μέτρα, όπως αυστηρές συμβατικές ρήτρες και τεχνική παραμετροποίηση.
              </li>
              <li>Πάροχοι λογισμικών και πλατφορμών επικοινωνίας, όπως η Rakuten Viber, η Drift και η Aircall. Τα δεδομένα σας 
                ενδέχεται να διαβιβαστούν εκτός Ευρωπαϊκού Χώρου και ιδίως στις ΗΠΑ από τις Drift και Aircall και στην Ιαπωνία 
                από την Rakuten Viber. Στο πλαίσιο αυτό, εφαρμόζουμε επιπλέον προστατευτικά μέτρα, όπως αυστηρές συμβατικές 
                ρήτρες και τεχνική παραμετροποίηση, ενώ η διαβίβαση στην Ιαπωνία θεωρείται ασφαλής χώρα (με απόφαση επάρκειας) 
                εκ μέρους της Ευρωπαϊκής Επιτροπής.
              </li>
              <li>Τραπεζικά ιδρύματα σε περίπτωση καταβολής κάποιας αποζημίωσης.</li>
              <li>Συνεργαζόμενες εταιρίες αποθήκευσης και μεταφοράς προϊόντων (logistics).</li>
              <li>Άλλες εταιρίες του Ομίλου Σκρουτζ.</li>
              <li>Εξωτερικοί σύμβουλοι, λογιστές και δικηγόροι.</li>
              <li>Οι αστυνομικές αρχές και οποιαδήποτε άλλη διοικητική, δικαστική ή δημόσια αρχή ή γενικά νομικό ή φυσικό 
                πρόσωπο προς το οποίο ενδέχεται βάσει νόμου ή δικαστικής απόφασης, να απορρέει σχετική υποχρέωση ή δικαίωμα 
                της SLM να ανακοινώνει τέτοια δεδομένα.
              </li>
            </ul>
          </p>
          <p class="p-align mt-1">
            Επιπλέον, επεξεργαζόμαστε τα στοιχεία των αποστολέων και των παραληπτών ως <span class="strong">Από Κοινού Υπεύθυνοι</span> 
            με την Σκρουτζ Α.Ε., στο πλαίσιο της βέλτιστης ενδοομιλικής συνεργασίας μας. Με δεδομένο πως η SLM διεκπεραιώνει 
            τις παραγγελίες που πραγματοποιούνται μέσω της πλατφόρμας ηλεκτρονικών αγορών που διατηρεί η Σκρουτζ Α.Ε., η 
            επεξεργασία αυτή είναι απαραίτητη, ειδικότερα, στο πλαίσιο της οικονομικής εκκαθάρισης και τιμολόγησης. Επίσης, 
            ενεργούμε ως Από Κοινού Υπεύθυνοι επεξεργασίας σε ό,τι αφορά ορισμένα γεγονότα σχετικά με την παραγγελία, όπως οι 
            επιστροφές προς τους συνεργαζόμενους με τη Σκρουτζ Α.Ε. εμπόρους, αλλά και τη διαχείριση των απολεσθέντων/ανεπίδοτων 
            και των αποζημιώσεων για τυχόν φθορές στο ταχυδρομικό αντικείμενο. Τέλος, η Σκρουτζ Α.Ε., έχοντας αναπτυγμένη 
            υλικοτεχνική δομή και εμπειρία στην εξυπηρέτηση πελατών, συνδράμει την SLM στο πλαίσιο της διαχείρισης των 
            εισερχομένων επικοινωνιών από τους χρήστες και το κοινό, επεξεργασίες για τις οποίες οι δύο εταιρίες λειτουργούν 
            επίσης ως Από Κοινού Υπεύθυνοι επεξεργασίας. Για τις ανωτέρω περιπτώσεις, συνάπτουμε ειδικές συμβατικές ρήτρες 
            προστασίας προσωπικών δεδομένων, ώστε να εξασφαλίζουμε ότι τηρούνται τα απαραίτητα μέτρα ασφαλείας των δεδομένων. 
            Για κάθε τυχόν ζήτημα που αφορά σε κάποια από τις ανωτέρω επεξεργασίες, παρακαλούμε θερμά να απευθύνεστε στον 
            Υπεύθυνο Προστασίας Προσωπικών Δεδομένων της Σκρουτζ Α.Ε. (dpo@skroutz.gr), ως αρμόδιο κανάλι επικοινωνίας.
          </p>
        </div>
        <div class="bussiness-specs">
          <h5>Πόσο Καιρό τα Διατηρούμε</h5>
          <p class="p-align mt-1">
            Η SLM διατηρεί τα προσωπικά δεδομένα των αποστολέων και των παραληπτών για όσο χρονικό διάστημα βρίσκεται σε ισχύ η 
            συμβατική σχέση ανάμεσά μας, και σε κάθε περίπτωση έως έξι (6) μήνες από την τελευταία αποτυχημένη προσπάθεια επίδοσης 
            του ταχυδρομικού αντικειμένου.
          </p>
          <p class="p-align mt-1">
            Μετά το πέρας της περιόδου αυτής και στην περίπτωση που δεν εκκρεμεί κάποια οικονομική οφειλή, δικαστική ή άλλη αμφισβήτηση 
            ή ειδικό έννομο συμφέρον που να απορρέει από τη συμβατική σας σχέση μαζί μας, τα δεδομένα θα διαγράφονται ή θα ανωνυμοποιούνται.
          </p>
        </div>
        <div class="bussiness-specs">
          <h5>Οι Δεσμεύσεις μας</h5>
          <p class="p-align mt-1">
            Λαμβάνουμε καθημερινά τα κατάλληλα τεχνικά και οργανωτικά μέτρα, σύμφωνα με τα τελευταία τεχνολογικά πρότυπα και την ισχύουσα 
            νομοθεσία, προκειμένου να διασφαλίσουμε ότι η επεξεργασία των προσωπικών δεδομένων, είτε από εμάς είτε από τρίτα μέρη για 
            λογαριασμό μας, είναι σύννομη, ενδεδειγμένη και διαθέτει το κατάλληλο επίπεδο ασφάλειας για να αποτρέψει κάθε μη εξουσιοδοτημένη 
            ή τυχαία πρόσβαση, επεξεργασία, διαγραφή, αλλοίωση ή άλλη χρήση αυτών.
          </p>
        </div>
        <div class="bussiness-specs">
          <h5>Τα Δικαιώματά σας</h5>
          <p class="p-align mt-1">
            Μπορείτε να ελέγξετε, να διορθώσετε ή/και να διαγράψετε τα δεδομένα σας ανά πάσα στιγμή, στέλνοντάς μας σχετικό αίτημα. 
            Γενικότερα, σύμφωνα με τη νομοθεσία για την προστασία προσωπικών δεδομένων έχετε και μπορείτε να ασκήσετε τα ακόλουθα δικαιώματα, 
            μετά από εξακρίβωση της ταυτότητάς σας:
            <ul>
              <li><span class="strong">το δικαίωμα πρόσβασης,</span> το δικαίωμα δηλαδή αφενός να ενημερωθείτε για το κατά πόσον τα προσωπικά σας δεδομένα υφίστανται 
                επεξεργασία από την SLM και αφετέρου να λάβετε περαιτέρω πληροφορίες σχετικά με την πραγματοποιούμενη επεξεργασία,
              </li>
              <li><span class="strong">το δικαίωμα να ζητήσετε τη διόρθωση</span> τυχόν ανακριβών προσωπικών σας δεδομένων ή τη συμπλήρωση αυτών,</li>
            </ul>
          </p>
          <p class="p-align mt-1">
            καθώς και εφόσον συντρέχουν οι προϋποθέσεις της νομοθεσίας:
            <ul>
              <li><span class="strong">το δικαίωμα διαγραφής,</span></li>
              <li><span class="strong">το δικαίωμα φορητότητας,</span> το δικαίωμα δηλαδή να λάβετε και να διαβιβάσετε σε άλλον υπεύθυνο επεξεργασίας τα προσωπικά 
                δεδομένα σας, τα οποία έχετε δώσει στην SLM, σε κατάλληλο μορφότυπο,
              </li>
              <li><span class="strong">το δικαίωμα περιορισμού</span> της επεξεργασίας,</li>
              <li><span class="strong">το δικαίωμα εναντίωσης</span>, ιδίως αναφορικά με τις επεξεργασίες που βασίζονται στο έννομο συμφέρον μας,</li>
              <li><span class="strong">το δικαίωμα να ανακαλέσετε ανά πάσα στιγμή τη συγκατάθεση που τυχόν μας έχετε δώσει,</span> χωρίς η ανάκληση αυτή να θίγει 
                τη νομιμότητα της επεξεργασίας προσωπικών δεδομένων που πραγματοποιήθηκε πριν την ανάκληση αυτή.
              </li>
            </ul>
          </p>
          <p class="p-align mt-1">
            Σε περίπτωση άσκησης των δικαιωμάτων διόρθωσης, διαγραφής και περιορισμού των δεδομένων σας, τα αιτήματα αυτά θα διαβιβάζονται και στους τρίτους 
            αποδέκτες στους οποίους τα δεδομένα αυτά τυχόν γνωστοποιήθηκαν από εμάς, στο πλαίσιο παροχής των ταχυδρομικών υπηρεσιών.
          </p>
          <p class="p-align mt-1">
            Η άσκηση των ανωτέρω δικαιωμάτων σας γίνεται με υποβολή σχετικού έγγραφου αιτήματός σας προς τη SLM, στο οποίο αναλαμβάνουμε να απαντήσουμε 
            εντός προθεσμίας ενός (1) μήνα από την παραλαβή του. Η προθεσμία αυτή μπορεί να παραταθεί για δύο (2) επιπλέον μήνες εξαιτίας πολυπλοκότητας 
            του αιτήματός, ή/και του μεγάλου όγκου των αιτημάτων.
          </p>
        </div>
        <div class="bussiness-specs">
          <h5>Στοιχεία Επικοινωνίας</h5>
          <p class="p-align mt-1">
            Για οποιαδήποτε απορία, διευκρίνιση ή αίτημά σας <span class="strong"><u>αποκλειστικά</u></span> για την επεξεργασία των προσωπικών δεδομένων σας, 
            παρακαλούμε όπως επικοινωνείτε με τον υπεύθυνο προστασίας προσωπικών δεδομένων της SLM:
          </p>
          <p class="p-align mt-1">
            ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ ΜΟΝΟΠΡΟΣΩΠΗ ΙΔΙΩΤΙΚΗ ΚΕΦΑΛΑΙΟΥΧΙΚΗ ΕΤΑΙΡΙΑ, Υπόψιν: Data Protection Officer, Αγίας Άννης 7, Τ.Κ. 118 55 Αθήνα | 
            <a href="mailto:dpo@skroutzdelivery.gr">dpo@skroutzdelivery.gr</a> . Για επικοινωνία <span class="strong">σχετικά με την αποστολή του πακέτου σας μέσω Skroutz,</span> 
            παρακαλούμε επικοινωνείτε μέσω <a href="mailto:help@skroutzdelivery.gr">help@skroutzdelivery.gr</a>.Για επικοινωνία σχετικά με την αποστολή του πακέτου σας εκτός Skroutz παρακαλούμε για επικοινωνία στο <a href="mailto:externalhelp@skroutzdelivery.gr">externalhelp@skroutzdelivery.gr</a>. Για τηλεφωνική επικοινωνία καλέστε μας στο 2111982107.
          </p>
        </div>
        <div class="bussiness-specs">
          <h5>Δικαίωμα Καταγγελίας</h5>
          <p class="p-align mt-1">
            Σε περίπτωση που θεωρείτε ότι δεν ικανοποιήσαμε επαρκώς το αίτημά σας και θίγεται κατά οποιονδήποτε τρόπο η προστασία των προσωπικών 
            σας δεδομένων, μπορείτε να υποβάλετε καταγγελία μέσω <a href="https://www.dpa.gr/el/syndesi/prosvasi">ειδικής διαδικτυακής πύλης</a> 
            στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα (Αθήνα, Λεωφόρος Κηφισίας 1-3, Τ.Κ. 115 23 | τηλ: +30 210 6475600). Αναλυτικές 
            οδηγίες υποβολής καταγγελίας παρέχονται στην <a href="https://www.dpa.gr/el/polites/katagelia_stin_arxi">Ιστοσελίδα</a> της Αρχής.
          </p>
        </div>

      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Footer from '../pages-sections/Footer.vue'
import Navbar from '../pages-sections/Navbar.vue'
export default {
  components: {
    Navbar, 
    Footer 
  },

}
</script>

<style lang="scss" scoped>
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: unset;
    border-radius: unset;
    
    h2 {
      font-weight: bold;
      line-height: 40px;
    }
  }
  .privacy-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;
    min-height: 600px;
    width: 100%;
    max-width: 780px;

    .p-align {
      text-align: justify;
    }
  }

  .bussiness-specs {
    margin-top: 1rem;

    h5 {
      font-weight: bold;
      font-size: 1.2em;
    }
    p {
      margin: 0.5rem 0;
    }
    ul {
      margin-left: 2rem;
      li {
        list-style-type: disc;
        margin-top: 12px;
        list-style-position: outside;
        
        &::before {
          content: "";
          display: inline-block;
        }
      }
    }
    .strong {
      font-weight: bold;
    }
  }
  .mt-1 {
    margin-top: 1rem;
  }
  .ml-1 {
    margin-left: 1rem !important;
  }
</style>
